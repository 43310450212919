import _ from "lodash";

export default {
  init(state) {
    return state.init;
  },
  myInfo(state) {
    return state.myInfo;
  },
  myBonuses(state) {
    return state.myBonuses;
  },
  myBonusesDrg(state) {
    return state.myBonusesDrg;
  },
  myCompanyCurrent(state) {
    return state.myCompanyCurrent;
  },
  isPopupStatuteOpen(state) {
    return state.isPopupStatuteOpen;
  },
  popupStatuteText(state) {
    return state.popupStatuteText;
  },
  lang(state) {
    return state.lang;
  },
  isPopupAccountOpen(state) {
    return state.isPopupAccountOpen;
  },
  isPopupClientOpen(state) {
    return state.isPopupClientOpen;
  },
  popupClient(state) {
    return state.popupClient;
  },
  popupPromotionClient(state) {
    return state.popupPromotionClient;
  },
  isPopupPromotionClientOpen(state) {
    return state.isPopupPromotionClientOpen;
  },
  promotion(state) {
    return state.promotion;
  },
  myClients(state) {
    return state.myClients?.clients || [];
  },
  myClientsLoading(state) {
    return state.myClientsLoading;
  },
  myClientsIx(state) {
    return _.map(
      state.myClients?.clients || [],
      (myClient) => myClient.client_ix
    );
  },
  ppClients(state) {
    return state.ppClients;
  },
  promotionClients(state) {
    let myClients = {};

    _.each(state.myClients.clients, (myClient) => {
      myClients[myClient.client_ix] = myClient;
    });

    return _.map(state.promotion?.users_registered || [], (user_registered) => {
      const myClient = myClients[user_registered.id] || {};

      return {
        ...user_registered,
        ...myClient,
      };
    });
  },
  myPpClients(state) {
    let ppClients = {};

    _.each(state.ppClients, (ppClient) => {
      ppClients[ppClient.ix] = ppClient;
    });

    return _.map(state.myClients?.clients || [], (myClient) => {
      const ppClient = ppClients[myClient.client_ix] || {};

      return {
        ...myClient,
        ...ppClient,
        has_account: ppClient.email ? "@PP" : "",
        //is_maxserwis: ppClient.maxserwis ? 'MXS' : ''
        is_maxserwis: myClient.client_maxserwis != "0" ? "MXS" : "",
      };
    });
  },
  wlm(state) {
    return state.wlm;
  },
  spn3(state) {
    return state.spn3;
  },
  apexpert(state) {
    return state.apexpert;
  },
  maxgearsky(state) {
    return state.maxgearsky;
  },
  viptotrip(state) {
    return state.viptotrip;
  },
  lnmwarsztat(state) {
    return state.lnmwarsztat;
  },
  lnmsklep(state) {
    return state.lnmsklep;
  },
  isOnline: (state) => state.isOnline,
  promptEvent: (state) => state.promptEvent,
  token: (state) => state.init.token,
  app_promotions: (state) => state.app_promotions || [],
  app_promotions_archive: (state) => state.app_promotions_archive || [],
  app_promotion: (state) => state.app_promotion,
  app_promotion_clients: (state) => state.app_promotion_clients,
};
