<template>
  <main class="main1">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 pab30">
          <h3>{{ $t("default.APpromotions") }}</h3>
        </div>
      </div>

      <p v-if="loading" class="loading">{{ $t("default.loading") }}</p>

      <div v-else class="prminiarea prar-new prsize1">
        <div class="row">
          <PromotionNewItem
            v-for="promotion in appPromotionsSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
          <PromotionItem
            v-for="promotion in promotions"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div
          v-if="
            appPromotionsArchiveSorted && appPromotionsArchiveSorted.length > 0
          "
          class="row promo-archive"
        >
          <div class="col-xs-12 pab30">
            <h3 style="color: #0c315d">
              {{ $t("default.APpromotionsArchive") }}
            </h3>
          </div>
          <PromotionNewItem
            v-for="promotion in appPromotionsArchiveSorted"
            :key="promotion.id"
            :promotion="promotion"
          />
        </div>
        <div v-if="noConnect" class="mat40 patb50 text-center">
          {{ $t("default.noData") }}
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PromotionItem from "@/components/PromotionItem";
import PromotionNewItem from "@/components/PromotionNewItem";
import { mapGetters, mapActions } from "vuex";
import { FETCH_PROMOTIONS } from "../store/actions.type";
import localforage from "localforage";

export default {
  components: {
    PromotionItem,
    PromotionNewItem,
  },
  data() {
    return {
      promotions: [],
      loading: false,
      noConnect: false,
    };
  },
  computed: {
    ...mapGetters([
      "isPopupStatuteOpen",
      "init",
      "myInfo",
      "app_promotions",
      "app_promotions_archive",
    ]),
    appPromotionsSorted() {
      if (this.app_promotions) {
        let data = JSON.parse(JSON.stringify(this.app_promotions));
        return data.sort(
          (a, b) => Date.parse(b.active_to) - Date.parse(a.active_to)
        );
      }
      return [];
    },
    appPromotionsArchiveSorted() {
      if (this.app_promotions_archive) {
        let data = JSON.parse(JSON.stringify(this.app_promotions_archive));
        return data.sort(
          (a, b) => Date.parse(b.active_to) - Date.parse(a.active_to)
        );
      }
      return [];
    },
  },
  async created() {
    try {
      const value = await localforage.getItem("vuex");
      if (!value) {
        this.loading = true;
      }
    } catch (err) {
      // This code runs if there were any errors
      // eslint-disable-next-line no-console
      console.log(err);
    }
    this.load();
  },
  methods: {
    ...mapActions([
      FETCH_PROMOTIONS,
      "FETCH_NEW_PROMOTIONS",
      "FETCH_NEW_PROMOTIONS_ARCHIVE",
    ]),
    async load() {
      if (!this.init || !this.myInfo) {
        return;
      }

      try {
        this.promotions = await this.FETCH_PROMOTIONS();
        await this.FETCH_NEW_PROMOTIONS();
        await this.FETCH_NEW_PROMOTIONS_ARCHIVE();
      } catch (error) {
        this.noConnect = error;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    init() {
      this.load();
    },
    myInfo() {
      this.load();
    },
  },
};
</script>
